import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import './footer.scss';
import Logo from '../../images/Lente_Automation-SVG_Logo-White.svg';


function Footer() {

  return (
    <footer className='footer'>
      <div className='footer-container'>
        {/* <span className='company-name-label'>Lente Automation AB</span> */}
        <img className='footer-logo' src={Logo} alt='Lente Automation Logo' />
        <div className='footer-info-wrapper'>
          <div className='footer-info-item'>
            <a href='https://www.google.com/maps/place/Kr%C3%A5kbacksv%C3%A4gen+9,+730+50+Skultuna/@59.7079268,16.430338,17z/data=!3m1!4b1!4m5!3m4!1s0x465e653bf17a8c87:0xea4d995824a20910!8m2!3d59.7079241!4d16.4325267'>
              <span className='material-icons'>place</span>
              <span>
              Brandthovdagatan 17a<br />
              721 35 Västerås
              </span>
            </a>
          </div>
          <div className='footer-info-item'>
            <a href='mailto:info@lenteautomation.se'>
              <span className='material-icons'>email</span>
              info@lenteautomation.se</a>
          </div>
          <div className='footer-info-item'>
            <a href='tel:+46736873301'>
              <span className='material-icons'>phone</span>
              073-6873301</a>
          </div>

          {/* <p>
          Fakturaadress:
          Lente Automation AB
          Kund-id FSX7580
          FE 301
          105 69 Stockholm
      </p> */}
          {/* <p>Faktura mail (PDF): inbox.lev.1092920@arkivplats.se</p> */}
          {/* org.nr: 559279-8127 */}
        </div>
      </div>
        <div className='copyright-info'>
          <span className='copyright-label'>&copy; {new Date().getFullYear().toString()} Lente Automation</span>
        </div>
    </footer>
  );
}

export default Footer;
