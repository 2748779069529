import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@fluentui/react/lib/Icon";
import "./navbar.scss";
import DarkModeLogo from "../../images/Lente_Automation-SVG_Logo-Original.svg";
import LightModeLogo from "../../images/Lente_Automation-SVG_Logo-Reversed.svg";

function Navbar() {
  const [click, setClick] = useState(false);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true" || false
  );
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const toggleDarkMode = () => setDarkMode(!darkMode);

  // let darkModeActive: boolean = false; //window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  // darkMode ? document.body.classList.add('darkTheme') : document.body.classList.remove('darkTheme');

  if (darkMode) {
    document.body.classList.add("darkTheme");
  }

  var themeOnClick = () => {
    if (darkMode) {
      localStorage.removeItem("darkMode");
      document.body.classList.remove("darkTheme");
    } else {
      localStorage.setItem("darkMode", "true");
      document.body.classList.add("darkTheme");
    }
    // darkMode ? localStorage.removeItem('darkMode') : localStorage.setItem('darkMode', 'true');
    // document.body.classList.remove('darkTheme') : document.body.classList.add('darkTheme');

    setDarkMode(!darkMode);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img
            src={darkMode ? DarkModeLogo : LightModeLogo}
            alt="Lente Automation Logo"
          />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <Icon iconName={click ? "Cancel" : "GlobalNavButton"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Start
            </Link>
          </li>
          {/* <li className='nav-item'>
            <Link to='/tjanster' className='nav-links' onClick={closeMobileMenu}>
              Tjänster
            </Link>
          </li> */}
          <li className="nav-item">
            <Link to="/om-oss" className="nav-links" onClick={closeMobileMenu}>
              Om oss
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/kontakt" className="nav-links" onClick={closeMobileMenu}>
              Kontakt
            </Link>
          </li>
          {/* <li>
            <button
              className="dark-mode-toggle"
              type="button"
              value="Theme"
              onClick={themeOnClick}
            >
              <span className="material-icons">brightness_medium</span>
            </button>
          </li> */}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
