import React from 'react';

function Contact() {
  return (
    <div className="contact-page">
      <header className="App-header">
        <h1>Kontakt</h1>
      </header>
      <div className='main-content'>
        <a className='contact-card email-card' href='mailto:info@lenteautomation.se'>
          <span className='material-icons'>email</span>
          info@lenteautomation.se</a>
        <a className='contact-card phone-card' href='tel:+46736873301'>
          <span className='material-icons'>phone</span>
          073-6873301</a>
        <h2>Leveransadress</h2>
        <address>
          Lente Automation AB<br />
          Brandthovdagatan 17a<br />
          721 35 Västerås
        </address>
        <div>
          <h2>Fakturaadress</h2>
          <p>Lente Automation AB<br />
            SC7365592798128<br />
            Box 171<br />
            831 22 Östersund
          </p>
          <p className='invoice-label'><span>Faktura mail (PDF):</span> <a href='mailto:faktura@lenteautomation.se'>faktura@lenteautomation.se</a></p>
          <p className='organisation-number-label'><span>org.nr:</span> 559279-8127</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
