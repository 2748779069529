import React from 'react';

function Services() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Tjänster</h1>
      </header>
      <div className='main-content'>
        <p className='lead'>Vi erbjuder följande tjänster.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. At dignissimos quam provident minus, id eaque, sapiente fuga itaque vitae odit voluptate doloribus. Recusandae voluptatibus, possimus sapiente ut neque provident doloremque.
        </p>
        <h2>Tjänst 1</h2>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad perferendis eius recusandae impedit minima fugiat autem id illum ab, quo earum, reprehenderit enim ea ducimus quasi debitis facere. Nesciunt, omnis.</p>
        <h2>Tjänst 2</h2>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad perferendis eius recusandae impedit minima fugiat autem id illum ab, quo earum, reprehenderit enim ea ducimus quasi debitis facere. Nesciunt, omnis.</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad perferendis eius recusandae impedit minima fugiat autem id illum ab, quo earum, reprehenderit enim ea ducimus quasi debitis facere. Nesciunt, omnis.</p>
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad perferendis eius recusandae impedit minima fugiat autem id illum ab, quo earum, reprehenderit enim ea ducimus quasi debitis facere. Nesciunt, omnis.</p>
      </div>
    </div>
  );
}

export default Services;
