import React from 'react';
import Contact from '../components/contact';

function Kontakt() {
  return (
    <>
      <Contact />
    </>
  )
}

export default Kontakt;