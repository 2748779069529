import React from "react";
// import Knx from "../images/knx-meterkast-1000x500.jpg";
// import Knx2 from "../images/KNX-Norra-station-Anders-B-2.jpg";

function Start() {
  const darkMode: boolean =
    localStorage.getItem("darkMode") === "true" || false;
  return (
    <div className="App">
      <div className="hero-block">
        <div className="hero-content-container">
          <h1>
            Välkommen till <br />
            Lente Automation
          </h1>
          <p className="lead">
            Vi hjälper dig med allt inom Styr och regler i fastigheter. Hör av
            dig så berättar vi mer!
          </p>
        </div>
        {/* <picture>
          <source media="(min-width:400px)" srcSet={Knx2} />
          <img src={Knx2} alt="" />
        </picture> */}
      </div>
      <div className="main-content">
        <p>
          Våra specialiteter är bland annat Larmia, SAIA, BASTEC, KNX och Carlo Gavazzi, <br />
          men ingenting är omöjligt. Kontakta oss så hjälper vi er att hitta en
          lösning för just er fastighet.
        </p>
        <div className="brands">
          <img
            src={
              process.env.PUBLIC_URL +
              (darkMode
                ? "/images/Larmia-logo-white.svg"
                : "/images/Larmia_Logo_Horizontal_rgb.png")
            }
            alt="Larmia"
          />
          <img
            src={
              process.env.PUBLIC_URL + "/images/SAIA.png"
            }
            alt="SAIA"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/BASTEC_logoprimary_RGB.svg"}
            alt="Bastec"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/1280px-KNX_logo.svg.png"}
            alt="KNX"
            className="knx-logo"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/cg_logo.svg"}
            alt="Carlo Gavazzi"
            className="gavazzi-logo"
          />
        </div>
      </div>
      <header className="App-header">
        {/* <img src={Knx} alt='KNX image'/> */}
      </header>
    </div>
  );
}

export default Start;
