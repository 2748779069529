import React from 'react';
import About from '../components/about';

function OmOss() {
  return (
    <>
      <About />
    </>
  )
}

export default OmOss;