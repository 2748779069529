import React from "react";
// import ake from "../images/pixabay-profile-square.jpg";

function About() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Om oss</h1>
      </header>
      <div className="main-content">
        <div className="company-description">
          <p className="lead"></p>
        </div>
        <div className="employees">
          <div className="employee-card">
            <div className="profile-picture">
              <img src="./images/Åke Lente_511x511.jpg" alt="Åke Lente bild" />
              {/* <img src={ake} alt='Åke Lente bild' /> */}
            </div>
            <div className="employee-info">
              <h2>Åke Lente</h2>
              <p className="job-title">Ägare/Programmerare</p>
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia deleniti quisquam consectetur sequi officiis beatae, nam debitis fugiat quae, odio, expedita vero? Beatae eum eos sequi perspiciatis iste itaque.
              </p> */}
              <p>
                <a href="tel:0736873301">
                  <span className="material-icons">phone</span> 073-6873301
                </a>
              </p>
              <p className="email">
                <span className="material-icons">email</span>
                <a href="mailto:ake.lente@lenteautomation.se">
                  &nbsp;ake.lente@lenteautomation.se
                </a>
              </p>
            </div>
          </div>
          {/* <div className="employee-card">
            <div className="profile-picture">
              <img
                src="./images/Isabella Sandberg_511x511.jpg"
                alt="Isabella Sandberg bild"
              />
            </div>
            <div className="employee-info">
              <h2>Isabella Sandberg</h2>
              <p className="job-title">Projektledare/Administratör</p>
              <p>
                <a href="tel:0736873320">
                  <span className="material-icons">phone</span> 073-6873320
                </a>
              </p>
              <p className="email">
                <span className="material-icons">email</span>
                <a href="mailto:isabella.sandberg@lenteautomation.se">
                  &nbsp;isabella.sandberg@lenteautomation.se
                </a>
              </p>
            </div>
          </div> */}
          <div className="employee-card">
            <div className="profile-picture">
              <img
                src="./images/Jimmy Bergh_511x511.jpg"
                alt="Jimmy Bergh bild"
              />
            </div>
            <div className="employee-info">
              <h2>Jimmy Bergh</h2>
              <p className="job-title">Projektledare/Programmerare</p>
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia deleniti quisquam consectetur sequi officiis beatae, nam debitis fugiat quae, odio, expedita vero? Beatae eum eos sequi perspiciatis iste itaque.
              </p> */}
              <p>
                <a href="tel:0736873340">
                  <span className="material-icons">phone</span> 073-6873340
                </a>
              </p>
              <p className="email">
                <span className="material-icons">email</span>
                <a href="mailto:jimmy.bergh@lenteautomation.se">
                  &nbsp;jimmy.bergh@lenteautomation.se
                </a>
              </p>
            </div>
          </div>
          <div className="employee-card">
            <div className="profile-picture">
              <img
                src="./images/Emil Strömberg_511x511.jpg"
                alt="Emil Strömberg bild"
              />
            </div>
            <div className="employee-info">
              <h2>Emil Strömberg</h2>
              <p className="job-title">Programmerare</p>
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia deleniti quisquam consectetur sequi officiis beatae, nam debitis fugiat quae, odio, expedita vero? Beatae eum eos sequi perspiciatis iste itaque.
              </p> */}
              <p>
                <a href="tel:0736873351">
                  <span className="material-icons">phone</span> 073-6873351
                </a>
              </p>
              <p className="email">
                <span className="material-icons">email</span>
                <a href="mailto:emil.stromberg@lenteautomation.se">
                  &nbsp;emil.stromberg@lenteautomation.se
                </a>
              </p>
            </div>
          </div>
          <div className="employee-card">
            <div className="profile-picture">
              <img src="./images/David Vesterberg_511x511.jpg" alt="Åke Lente bild" />
              {/* <img src={ake} alt='Åke Lente bild' /> */}
            </div>
            <div className="employee-info">
              <h2>David Vesterberg</h2>
              <p className="job-title">Projektledare/Servicetekniker</p>
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia deleniti quisquam consectetur sequi officiis beatae, nam debitis fugiat quae, odio, expedita vero? Beatae eum eos sequi perspiciatis iste itaque.
              </p> */}
              <p>
                <a href="tel:0767208460">
                  <span className="material-icons">phone</span> 076-7208460
                </a>
              </p>
              <p className="email">
                <span className="material-icons">email</span>
                <a href="mailto:david.vesterberg@lenteautomation.se">
                  &nbsp;david.vesterberg@lenteautomation.se
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
