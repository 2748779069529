import React from 'react';
import Services from '../components/services';

function Tjanster() {
  return (
    <>
      <Services />
    </>
  )
}

export default Tjanster;