import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles.scss";
import reportWebVitals from "./reportWebVitals";
import Navbar from "./components/navbar/navbar";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import Footer from "./components/footer/footer";
import Home from "./pages/home";
import Tjanster from "./pages/tjanster";
import OmOss from "./pages/om-oss";
import Kontakt from "./pages/kontakt";
import ScrollToTop from "./components/utilities.tsx/scroll-to-top";

initializeIcons();

// let darkMode: boolean = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

// ReactDOM.render(
//   <React.StrictMode>
//     <Router>
//       <ScrollToTop />
//       <Navbar />
//       <Switch>
//         <Route path='/' exact component={Home} />
//         <Route path='/tjanster' exact component={Tjanster} />
//         <Route path='/om-oss' exact component={OmOss} />
//         <Route path='/kontakt' exact component={Kontakt} />
//       </Switch>
//     </Router>
//     {/* <App /> */}
//     {/* <Start /> */}
//     <Footer />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/tjanster" exact component={Tjanster} />
        <Route path="/om-oss" exact component={OmOss} />
        <Route path="/kontakt" exact component={Kontakt} />
      </Switch>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>,
  // <React.StrictMode>
  //   <Router>
  //     <ScrollToTop />
  //     <Navbar />
  //     <Switch>
  //       <Route path='/' exact component={Home} />
  //       <Route path='/tjanster' exact component={Tjanster} />
  //       <Route path='/om-oss' exact component={OmOss} />
  //       <Route path='/kontakt' exact component={Kontakt} />
  //     </Switch>
  //   </Router>
  //   {/* <App /> */}
  //   {/* <Start /> */}
  //   <Footer />
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
