import React from 'react';
import Start from '../components/start';

function Home() {
  return (
    <>
      <Start />
    </>
  )
}

export default Home;